.BottomNavigation {
  position: absolute;
  

  bottom: 10px;
  left: 0px;
  
  height: 50px;
  width: 100%;
    

  text-align: center;

  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 5;
 
  text-align: center;
}

.quiz {
  background-repeat: no-repeat;
  grid-row-gap:15px ;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  padding: 15px;
  align-self: center;
  padding-top: 70%;
  align-items: center;
  overflow:auto;
}
@media screen and (min-width: 530px) {
  .quiz {
    flex-direction: column;
    align-items: right;
    padding-top: 0%;
    width: 100%;
   
  }
  .cardmedia{
    display: none;
  }
  .quizs{
    width: 50%;
    display: block;
    justify-content: right;
    align-items: right;
    
  }
  
}

.quizs{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  
}

.formquiz{
  
 align-items: center;
 
  
}

.pagequiz{
  justify-content: center;
  
  position: absolute;
  display: flex;
  align-items: center;
  align-self: center;
}

